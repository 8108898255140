<template>
  <div v-if="uploadProgress === -1" class="drop-file-area">
    <div
      class="content-area"
      @dragover.prevent="handleDragOver"
      @dragenter.prevent="handleDragEnter"
      @dragleave.prevent="handleDragLeave"
      @drop.prevent="handleFileDrop"
      :class="{ highlight: isDraggingOver }"
    >
      <div>Drag and drop a video</div>
      <p class="caption">File size can be up to 50MB</p>

      <SecondBtn @click="triggerFileInput">
        Or select a file
      </SecondBtn>
      
      <input
        type="file"
        ref="fileInput"
        @change="handleFileChange"
        style="display: none"
        accept="video/mp4, video/quicktime, video/x-matroska"
      />
    </div>
  </div>

  <div v-else class="progress-container">
    <div class="header-2">
      Please wait while the video is uploading and being processed
    </div>
    <div class="progress-placeholder">
      <svg class="progress-circle" width="100" height="100">
        <defs>
          <radialGradient id="progress-gradient" cx="50%" cy="50%" r="50%">
            <stop offset="26%" stop-color="#20AD8B" />
            <stop offset="76%" stop-color="#ACE41B" />
          </radialGradient>
        </defs>
        <g transform="rotate(-90, 50, 50)">
          <circle
            class="progress-circle-bg"
            cx="50"
            cy="50"
            r="45"
            fill="transparent"
            stroke="#eee"
            stroke-width="10"
          />
          <circle
            class="progress-circle-fg"
            cx="50"
            cy="50"
            r="45"
            fill="transparent"
            stroke="url(#progress-gradient)"
            stroke-width="10"
            :stroke-dasharray="circumference"
            :stroke-dashoffset="
              circumference - (uploadProgress / 100) * circumference
            "
            stroke-linecap="round"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import SecondBtn from '@/components/SecondBtn.vue';


const store = useStore();
const router = useRouter();

const loaderRadius = 45;
const circumference = computed(() => 2 * Math.PI * loaderRadius);
const isDraggingOver = ref(false);
const uploadProgress = ref(-1);

const fileInput = ref(null);

function handleDragOver(event) {
  event.preventDefault();
}

function handleDragEnter(event) {
  event.preventDefault();
  isDraggingOver.value = true;
}

function handleDragLeave(event) {
  event.preventDefault();
  isDraggingOver.value = false;
}

function handleFileChange(event) {
  const files = event.target.files;
  if (files.length > 0) {
    validateAndUploadFile(files[0]);
  }
}

function triggerFileInput() {
  fileInput.value.click();
}

function validateAndUploadFile(file) {
  const validFileTypes = ["video/mp4", "video/quicktime", "video/x-matroska"];
  const validFileExtensions = [".mp4", ".mov", ".mkv"];

  const cleanedFileName = file.name.split(".")[0];
  const isValidType = validFileTypes.includes(file.type);
  const isValidExtension = validFileExtensions.some((ext) =>
    cleanedFileName.toLowerCase().endsWith(ext)
  );

  if ((isValidType || isValidExtension) && file.size <= 52428800) {
    uploadFile(file);
  } else {
    isDraggingOver.value = false;
    alert("Please drop a valid video file of up to 50MB.");
  }
}

function handleFileDrop(event) {
  event.preventDefault();

  const files = event.dataTransfer.files;
  if (files.length > 0) {
    const file = files[0];
    const validFileTypes = ["video/mp4", "video/quicktime", "video/x-matroska"];
    const validFileExtensions = [".mp4", ".mov", ".mkv"];

    const cleanedFileName = file.name.split(".")[0];
    const isValidType = validFileTypes.includes(file.type);
    const isValidExtension = validFileExtensions.some((ext) =>
      cleanedFileName.toLowerCase().endsWith(ext)
    );

    if ((isValidType || isValidExtension) && file.size <= 52428800) {
      // 50MB limit
      uploadFile(file);
    } else {
      isDraggingOver.value = false;
      alert("Please drop a valid video file of up to 50MB.");
    }
  }
}

function uploadFile(file) {
  const formData = new FormData();
  const sessionId = store.getters.getSessionId;
  const url = `${process.env.VUE_APP_BACKEND_URL}/upload-video/`;

  formData.append("video_file", file);

  const xhr = new XMLHttpRequest();
  xhr.open("POST", url, true);
  xhr.setRequestHeader("session-id", sessionId);

  xhr.upload.onprogress = function (event) {
    if (event.lengthComputable) {
      const percentComplete = (event.loaded / event.total) * 100;
      uploadProgress.value = percentComplete / 2;
    }
  };

  xhr.onload = function () {
    if (xhr.status === 200) {
      const response = JSON.parse(xhr.responseText);

      const animationId = response.hashed_id;

      router.push({ path: "/animation", query: { animationId: animationId } });
    } else {
      alert("Failed to upload file");
      resetUploadState();
    }
  };

  xhr.onerror = function () {
    console.error("Error uploading file");
    alert("Error uploading file");
    resetUploadState();
  };

  xhr.send(formData);
}

function resetUploadState() {
  uploadProgress.value = -1;
  isDraggingOver.value = false;
}
</script>

<style scoped>
.drop-file-area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 30%;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  text-align: center;
  margin: 20px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.content-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 70%;
  border: 2px dashed rgb(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 8px;
}

.caption {
  color: rgba(255, 255, 255, 0.4);
}

.header-2 {
  margin-bottom: 12px;
}

.highlight {
  border-color: grey;
  background-color: rgba(255, 255, 255, 0.2);
}

.progress-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.progress-placeholder {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 12px;
}

.progress-circle-bg,
.progress-circle-fg {
  transition: stroke-dashoffset 0.3s linear;
}
</style>
